import './anchor-navigation.scss';
import EventScroller from '../../utilities/js/event-scroller';
import { getOffset } from '../../utilities/js/helper';

class AnchorNavigation {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-anchor-navigation',
            anchorAttr: 'anchor',
            listAttr: 'items',
            prevAttr: 'prev',
            nextAttr: 'next',
            itemAttr: 'item',
            eventScroller: null
        };

        this.settings = Object.assign({}, settings, options);
        this.$anchorNavigation = element;
        this.$anchorNavigationList = this.$anchorNavigation.querySelector('[data-anchor-navigation="items"]');
        this.$anchorNavigationItems = this.$anchorNavigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.itemAttr + '"]');
        this.eventScroller = null;
        this.anchorTimeout = null;
        this.isScrolling = false;
        this.$siteheader = document.querySelector('[data-header="root"]');
        this.$anchorNavigationPrev = this.$anchorNavigation.querySelector('[data-anchor-navigation="prev"]');
        this.$anchorNavigationNext = this.$anchorNavigation.querySelector('[data-anchor-navigation="next"]');

        this.eventScroller = new EventScroller({
            onScroll: () => {
                this.checkAnchorPosition();
            }
        });
        this.eventScroller.initialize();

        this.initialize();
    }

    initialize () {
        this.checkAnchorPosition();
        this.setEvents();

        if (this.$anchorNavigationPrev && this.$anchorNavigationNext) {
            this.initNavigation();
            this.checkButtons();
        }
    }

    setEvents () {
        for (let i = 0; i < this.$anchorNavigationItems.length; i++) {
            const $currentItem = this.$anchorNavigationItems[i].querySelector('a[href]');

            $currentItem.addEventListener('click', (e) => {
                const targetId = $currentItem.getAttribute('href').split('#')[1];
                const $target = document.getElementById(targetId).parentNode;

                if ($target !== null) {
                    const anchorDimensions = getOffset($target);
                    const anchorDimensionsTop = anchorDimensions.top - this.$anchorNavigation.offsetHeight;

                    this.isScrolling = true;

                    this.smoothScrollTo(anchorDimensionsTop).then(() => {
                        this.isScrolling = false;
                    });

                    this.setActiveAnchorItem(targetId);
                }

                e.preventDefault();
            });
        }

        this.$anchorNavigation.addEventListener('scroll', () => {
            this.checkButtons();
        });

        window.addEventListener('resize', () => {
            this.checkButtons();
        });
    }

    smoothScrollTo(top) {
        return new Promise((resolve) => {
            const scrollOptions = {
                top: top,
                left: 0,
                behavior: 'smooth'
            };
            window.scrollTo(scrollOptions);

            const checkIfScrollFinished = () => {
                if (Math.abs(window.scrollY - top) < 1) {
                    resolve();
                } else {
                    requestAnimationFrame(checkIfScrollFinished);
                }
            };

            requestAnimationFrame(checkIfScrollFinished);
        });
    }

    checkButtons () {
        const currentPosition = this.$anchorNavigation.scrollLeft;
        const listWidth = this.$anchorNavigationList.offsetWidth;
        const wrapperWidth = this.$anchorNavigation.offsetWidth;

        if (currentPosition > 0) {
            this.$anchorNavigationPrev.classList.remove('is--disabled');
        } else {
            this.$anchorNavigationPrev.classList.add('is--disabled');
        }

        if (wrapperWidth + currentPosition < listWidth) {
            this.$anchorNavigationNext.classList.remove('is--disabled');
        } else {
            this.$anchorNavigationNext.classList.add('is--disabled');
        }
    }

    initNavigation () {
        this.$anchorNavigationPrev.addEventListener('click', () => {
            this.goToPreviousItem();
        });

        this.$anchorNavigationNext.addEventListener('click', () => {
            this.goToNextItem();
        });
    }

    goToPreviousItem () {
        let scrollLeft = 0;
        const $prevElement = this.getNextElement(true);

        if ($prevElement) {
            scrollLeft = this.calculateCenterPosition($prevElement);
        }

        this.$anchorNavigation.scrollTo({
            top: 0,
            left: scrollLeft,
            behavior: 'smooth'
        });
    }

    goToNextItem () {
        let scrollLeft = 0;
        const $nextElement = this.getNextElement();

        if ($nextElement) {
            scrollLeft = this.calculateCenterPosition($nextElement);
        }

        this.$anchorNavigation.scrollTo({
            top: 0,
            left: scrollLeft,
            behavior: 'smooth'
        });
    }

    getNextElement (prev = false) {
        const currentPosition = this.$anchorNavigation.scrollLeft;
        let $nextItem = null;

        for (let i = 0; i < this.$anchorNavigationItems.length; i++) {
            const $anchorNavigationItem = this.$anchorNavigationItems[i];
            const scrollCenterPosition = this.calculateCenterPosition($anchorNavigationItem);
            if (scrollCenterPosition >= currentPosition) {
                if (prev === true) {
                    if (i > 0) {
                        $nextItem = this.$anchorNavigationItems[i - 1];
                    }
                } else {
                    if (i < this.$anchorNavigationItems.length) {
                        $nextItem = this.$anchorNavigationItems[i + 1];
                    }
                }
                break;
            }
        }

        return $nextItem;
    }

    calculateCenterPosition ($item) {
        const itemOffsetLeft = $item.offsetLeft;
        const wrapperWidth = this.$anchorNavigation.offsetWidth;
        const firstItemOffsetLeft = this.$anchorNavigationItems[0].offsetLeft;
        const innerWrapperWidthHalf = 0.5 * (wrapperWidth - firstItemOffsetLeft * 2);
        const itemWidthHalf = $item.offsetWidth / 2;
        const scrollPosition = itemOffsetLeft - firstItemOffsetLeft - (innerWrapperWidthHalf - itemWidthHalf);
        return scrollPosition;
    }

    setActiveAnchorItem (id) {
        let $activeTarget = null;

        if (id === null) {
            $activeTarget = this.$anchorNavigation.querySelectorAll('a')[0];
        } else {
            $activeTarget = this.$anchorNavigation.querySelector('a[href="#' + id + '"]');
        }

        if ($activeTarget == null) {
            return false;
        }

        const $activeItem = $activeTarget.parentNode;

        if (id !== null) {
            for (let a = 0; a < this.$anchorNavigationItems.length; a++) {
                const $currentItem = this.$anchorNavigationItems[a];
    
                if ($currentItem === $activeItem) {
                    const listWidth = this.$anchorNavigationList.offsetWidth;
                    const wrapperWidth = this.$anchorNavigation.offsetWidth;

                    if (listWidth > wrapperWidth) {
                        const scrollLeft = this.calculateCenterPosition($currentItem);
        
                        this.$anchorNavigation.scrollTo({
                            top: 0,
                            left: scrollLeft,
                            behavior: 'smooth'
                        });
                    }

                    $currentItem.classList.add('anchor-navigation__item--active');
                    history.replaceState(null, null, '#' + id);
                } else {
                    $currentItem.classList.remove('anchor-navigation__item--active');
                }
            }
        } else {
            if ($activeItem.classList.contains('anchor-navigation__item--active')) {
                $activeItem.classList.remove('anchor-navigation__item--active');
                history.replaceState(null, null, window.location.pathname + window.location.search);
            }
        }
    }

    checkAnchorPosition () {
        if (!this.isScrolling) {
            const $anchors = document.querySelectorAll('.anchor');
            for (let i = 0; i < $anchors.length; i++) {
                const $currentAnchor = $anchors[i];
                const elementDimensions = $currentAnchor.getBoundingClientRect();
                const elementTop = elementDimensions.top;
    
                if (elementTop - window.innerHeight / 2 <= 0) {
                    this.currentActiveId = $currentAnchor.getAttribute('id');
                } else {
                    if (i === 0) {
                        this.currentActiveId = null;
                    }
                }
            }
    
            if (this.activeAnchorId !== this.currentActiveId) {
                this.activeAnchorId = this.currentActiveId;
    
                this.setActiveAnchorItem(this.activeAnchorId);
            }
        }
    }
}

export { AnchorNavigation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const context = eventDetails.$context;

    if (context) {
        const $anchorNavigation = context.querySelector('[data-anchor-navigation="root"]');
        if ($anchorNavigation) {
            const anchorNavigationAPI = new AnchorNavigation($anchorNavigation);
            $anchorNavigation.anchorNavigationAPI = anchorNavigationAPI;
        }
    }
});
